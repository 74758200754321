<template>
  <div class="Uploads">
    <v-container>
      <v-row>
        <v-col>
          <v-btn
            class="center fp pl-8 pr-8"
            x-large
            rounded
            outlined
            dark
            :disabled="!isUploadLocked"
            @click="newUpload()"
            >{{ $t("app.dailyUploads") }}</v-btn
          >
        </v-col>
      </v-row>

      <v-card dark v-if="isFilter">
        <v-card-text>
          <v-checkbox
            class="mt-0"
            v-model="isPending"
            label="Függőben"
          ></v-checkbox>
          <v-checkbox
            class="mt-0"
            v-model="isFeedback"
            label="Értékelve"
          ></v-checkbox
        ></v-card-text>
      </v-card>
    </v-container>

    <v-list>
      <v-list-item v-if="noResult">
        <v-card>
          <v-card-text>Nincsenek Feltöltések</v-card-text>
        </v-card>
      </v-list-item>
      <v-list-item v-for="item in items" :key="item.id" class="rscope">
        <v-list-item-avatar
          ><v-img src="../assets/hell_app_logo.png"></v-img
        ></v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title
            ><b class="mr-2"
              >{{ item.user.lastName }} {{ item.user.firstName }}</b
            >
            <small>{{ toDate(item.createdAt) }}</small></v-list-item-title
          >
          <div class="tiles mt-2 d-flex flex-wrap">
            <v-sheet
              v-for="(upload, index) in item.uploadImages"
              :key="index"
              class="sheet d-flex pa-1 ma-1"
              elevation="4"
            >
              <div class="rscope">
                <v-img
                  max-width="60"
                  height="60"
                  :src="buildImage(upload)"
                  @click="enlarge(upload)"
                ></v-img>
                <div class="overlay" v-if="item.status !== 'PENDING'">
                  <v-btn
                    @click="showStatus(item, upload)"
                    fab
                    small
                    :color="upload.status !== 'REJECTED' ? 'black' : 'red'"
                  >
                    <v-icon color="white">{{
                      upload.status !== "REJECTED" ? "mdi-check" : "mdi-close"
                    }}</v-icon>
                  </v-btn>
                </div>
              </div>
            </v-sheet>
          </div>
        </v-list-item-content>
        <div v-if="item.status === 'PENDING'" class="overlay">
          <div class="pa-2" style="background-color: rgba(255, 255, 255, 0.8)">
            {{ $t("store.pending") }}
          </div>
        </div>
      </v-list-item>
    </v-list>
    <v-pagination
      v-if="hasPagination"
      v-model="currentPage"
      :length="totalPages"
      total-visible="3"
      class="pagination"
    ></v-pagination>
    <v-dialog v-model="isPreview">
      <v-img v-if="isPreview" :src="activePreview"></v-img>
    </v-dialog>

    <v-dialog class="rscope" v-model="isRejected">
      <v-card dark>
        <v-card-text>
          <v-img
            style="width: 100vw; height: 100vh; object-fit: cover"
            :src="this.activeRejected"
          ></v-img
        ></v-card-text>
      </v-card>
      <div
        style="
          text-align: center;
          background-color: rgba(255, 0, 0, 0.7);
          border-top: 3px solid #000;
          position: absolute;
          bottom: 0;
          padding: 2rem;
          left: 0;
          right: 0;
          color: #fff;
        "
        v-if="isRejected"
        class="mesaage"
      >
        <h4 class="mb-4">Elutasítás oka</h4>
        <p>{{ activeRejectionMessage }}</p>
        <v-btn
          class="fp"
          @click="isRejected = false"
          x-large
          color="primary"
          elevation="0"
          rounded
          >RENDBEN <v-icon class="ml-2">mdi-check</v-icon></v-btn
        >
      </div>
    </v-dialog>
  </div>
</template>

<script>
import eventBus from "../eventBus.js";
import moment from "moment";
import { API_BASE, IMAGE_BASE } from "../util/constants";
import {
  getMultipartHeader,
  getLoggedInHeader,
  toQueryString,
} from "../util/functions.js";
export default {
  name: "Uploads",
  components: {},
  created() {
    eventBus.$emit("RIGHT_NAV", "FILTER");
    eventBus.$on("SHOW_FILTER", () => {
      this.isFilter = !this.isFilter;
    });
  },
  destroyed() {
    eventBus.$emit("RIGHT_NAV", "DEFAULT");
    eventBus.$off("SHOW_FILTER");
  },
  mounted() {
    this.getPastList();
  },

  data() {
    return {
      items: [],
      currentPage: 1,
      hasPagination: false,
      totalPages: 1,
      defaultFilter: {
        endDate: Date.now(),
        startDate: 0,
        uploadStatuses: ["PENDING", "FEEDBACK"],
      },
      isFilter: false,
      isUploadLocked: false,
      isPreview: false,
      activePreview: null,
      activeRejected: null,
      statusFilter: [0, 1, 2],
      activeRejectionMessage: "",
      isRejected: false,
      isPending: true,
      isFeedback: true,
      noResult: false,
      store: {},
    };
  },
  methods: {
    refreshStore() {
      this.$store.dispatch("getStore").then(() => {
        this.store = this.$store.getters.getStore;
        let days = parseInt(this.store.numberOfDaysAfterLastUpload);

        if (isNaN(days)) {
          this.isUploadLocked = true;
        } else {
          if (days > 0) {
            this.isUploadLocked = true;
          } else {
            this.isUploadLocked = false;
          }
        }
      });
    },
    newUpload() {
      this.$router.push("/feltoltes");
    },
    toDate(date) {
      return moment.unix(date).format("YY.MM.DD");
    },
    buildImage(image) {
      let name = image.filename;
      let folder = image.dirPath.substring(1);
      return `${IMAGE_BASE}${folder}${name}`;
    },
    enlarge(_image) {
      const image = this.buildImage(_image);
      this.isPreview = true;
      this.activePreview = image;
    },
    showStatus(item, image) {
      this.activeRejectionMessage = image.rejectCause;
      this.activeRejected = this.buildImage(image);
      this.isRejected = image.status === "REJECTED";
    },
    async getPastList() {
      this.refreshStore();
      this.defaultFilter.uploadStatuses = ["PENDING", "FEEDBACK"];

      if (!this.isPending) this.defaultFilter.uploadStatuses = ["FEEDBACK"];
      if (!this.isFeedback) this.defaultFilter.uploadStatuses = ["PENDING"];

      const requestOptions = {
        method: "PATCH",
        headers: getLoggedInHeader(localStorage.getItem("token")),
        body: JSON.stringify(this.defaultFilter),
        redirect: "follow",
      };
      try {
        let response = await fetch(
          `${API_BASE}client/uploads/page/${this.currentPage}/filtered`,
          requestOptions
        );

        if (response.ok) {
          const jsonResponse = await response.json();
          this.items = jsonResponse.data;
          this.hasPagination = jsonResponse.totalPages > 1;
          this.totalPages = jsonResponse.totalPages;
          if (this.items.length < 1) {
            this.noResult = true;
          } else {
            this.noResult = false;
          }
        } else {
          if (response.status === 403) {
            this.$store.dispatch("purgeAll");
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        eventBus.$emit("LOAD_OFF");
      }
    },
  },
  computed: {
    statusFilterString() {
      let a = [];
      if (this.statusFilter.includes(0)) a.push("PENDING");
      if (this.statusFilter.includes(1)) a.push("ACCEPTED");
      if (this.statusFilter.includes(2)) a.push("REJECTED");
      return a;
    },
  },
  watch: {
    isPending(val) {
      if (!val) this.isFeedback = true;
      this.currentPage = 1;
      this.getPastList();
    },
    isFeedback(val) {
      if (!val) this.isPending = true;
      this.currentPage = 1;
      this.getPastList();
    },
    currentPage() {
      this.getPastList();
    },
  },
};
</script>
<style scoped>
.center {
  display: block;
  margin: 0 auto;
}
.v-list-item__avatar {
  align-self: flex-start;
}
</style>
<style>
.tiles .v-image__image {
  border-radius: 3px;
  border: 1px solid #000;
}
.v-dialog {
  margin: 0 !important;
  max-height: 100% !important;
}
.rejection-card .v-sheet {
  border-top: 3px solid #000 !important;
  margin: 0;
}
.rejection-card p,
.rejection-card h3 {
  color: #fff !important;
}
.v-btn-toggle {
  flex-direction: column !important;
  width: 100%;
}
</style>
<style></style>
